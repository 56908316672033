import React, { useState, useEffect } from 'react';
import {Table,Container,Row,Col,Button,Accordion,Form,Toast,Navbar,Card,Nav,Badge} from "react-bootstrap";
import logo from '../images/fpl_green.png';
import '../css/App.css';
import { withAuthenticator,AmplifyAuthContainer,AmplifyAuthenticator,AmplifySignIn,AmplifySignOut} from '@aws-amplify/ui-react';
// import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignUp, VerifyContact} from 'aws-amplify-react';
import { Auth }  from 'aws-amplify';
const AWS = require("aws-sdk");
const axios = require("axios");
import loadable from '@loadable/component';
const BootstrapSwitchButton = loadable(() => import('bootstrap-switch-button-react'));

var cloudwatchevents;
var lambda;
// async function getSession(){
//
//     // Auth.currentSession()
//     //   .then(data => console.log(data))
//     //   .catch(err => console.log(err));
//
//   await Auth.currentAuthenticatedUser({
//         bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
//     }).then(user => {
//       const idT = user.signInUserSession.idToken.jwtToken;
//       console.log(idT);
//       AWS.config.region = 'eu-west-1'; // Region
//       AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//        IdentityPoolId: 'eu-west-1:af9ead72-49bd-4566-9abb-bbe3eaaf3909',
//        Logins: {
//          'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_9jhIk35RA': idT
//        }
//    });
//    console.log(AWS.config.credentials);
//    AWS.config.credentials.get(function(err) {
//      if (err) {
//        console.log("Error: "+err);
//        return;
//      }
//      console.log("Cognito Identity Id: " + AWS.config.credentials.identityId);
//      // loggedIn=true;
//   });
//   // useCWState();
//   // getCloudWatch();
//   var params = {
//   };
//   //             var cloudwatchevents = new AWS.CloudWatchEvents();
//   var cloudwatchevents = new AWS.CloudWatchEvents();
//   cloudwatchevents.listRules(params, function (err, data) {
//     if (err) {console.log(err, err.stack);
//       return null;} // an error occurred
//     else     {
//         // console.log(data);           // successful response
//
//         const dataRules2 = data.Rules;
//         // console.log(dataRules[1].Name)
//
//         console.log(dataRules2.length)
//         console.log("userHasAuthenticated_seesion_in")
//         return dataRules2;
//
//         // setdataRules(data.Rules);
//         // console.log(dataRules[1]);
//     }
//   });
//
//     })
//     .then(console.log("userHasAuthenticated_seesio"))
//     .catch(err => console.log(err));
// }


function enable_rule(rx,checked_state,refreshCW,changeToast){

var params = {Name:rx};
console.log(checked_state);
cloudwatchevents = new AWS.CloudWatchEvents({apiVersion: '2015-10-07'});

if(checked_state==false){
  cloudwatchevents.enableRule(params, function (err, data) {
  // if (err) console.log(err, err.stack); // an error occurred
  if (err) {
    console.log(err, err.stack);
    alert("ERROR");
      changeToast("FAILED!!! CHECK!!!");
  } // an error occurred
  else    {console.log(data);
  refreshCW();}          // successful response
  });
  }
  else{
    cloudwatchevents.disableRule(params, function (err, data) {
    if (err) console.log(err, err.stack); // an error occurred
    else    {console.log(data);
    refreshCW();}          // successful response
    });
  }
}

// function CwRules(props) {
//   const [checked, setChecked] = useState(false);
//   const radios = props.cw;
//   const refreshCW = props.refreshCW;
//
//   return (
//     <>
// {radios.map((radio, idx) => {
//   var checked_state = radio.State==="ENABLED" ? true : false;
//
//   return (
//   <React.Fragment  key={idx}>
//   <ToggleButton
//     key={`tb-${idx}`}
//     className="mb-2"
//     id={`toggle-check-${idx}`}
//     type="checkbox"
//     variant="outline-primary"
//     size="sm"
//     checked={checked_state}
//     value={idx}
//     onChange={(e) => enable_rule(radio.Name,e.currentTarget.checked,refreshCW)}
//     style={{width:"100%",color:"white",textAlign:"left"}}
//   >
//     {"  "+radio.Name}
//   </ToggleButton>
//   <br key={`br-${idx}`} />
//
//   </React.Fragment>
// )})}
// </>
// );
// }

function CwRules2(props) {
  const [checked, setChecked] = useState(false);
  const radios = props.cw;
  const refreshCW = props.refreshCW;

  return (
    <>

{radios.map((radio, idx) => {
  var checked_state = radio.State==="ENABLED" ? true : false;

  return (
  <React.Fragment key={idx}>
  <div style={{width:"100%",fontSize: "14px"}}>
<BootstrapSwitchButton
    key={idx}
    onstyle="warning" offstyle="secondary" style="border"
    checked = {checked_state}
    onlabel = {radio.Name}
    offlabel = {radio.Name}
    onChange={() => enable_rule(radio.Name,checked_state,refreshCW,props.changeToast)}
    size="xs"
    width={300}
  />
  </div>
  </React.Fragment>
)})}
<br/>
</>
);
}




// function LambdaRules(props) {
//   const [checked, setChecked] = useState(false);
//   const radios = props.lambdas;
//
//   return (
//     <>
// {radios.map((radio, idx) => {
//
//   return (
//   <React.Fragment  key={idx}>
//   <ToggleButton
//     key={`tb-${idx}`}
//     className="mb-2"
//     id={`toggle-check-${idx}`}
//     type="radio"
//     variant="outline-primary"
//     size="sm"
//     checked="unchecked"
//     value={idx}
//     style={{width:"100%",color:"white",textAlign:"left"}}
//   >
//     {"  "+radio.FunctionName}
//   </ToggleButton>
//   <br key={`br-${idx}`} />
//
//   </React.Fragment>
// )})}
// </>
// );
// }

function LambdaRules2(props) {
  // const [checked, setChecked] = useState(false);
  const radios = props.lambdas;
  const lamdaSelect = props.lamdaSelect;
  const changeLamdaFunc = props.changeLamdaFunc;
          // onChange={() => changeLamda(radio.FunctionName)};

  return (
    <div style={{width:"100%"}}>
    <Form>
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Control
          as="select" value={lamdaSelect}
          size="lg"
          onChange={(e) => changeLamdaFunc(e.currentTarget.value)}
          >
          {radios.map((radio, idx) => {
            if(radio.FunctionName.match(/amplify.*/)){
              return null;
            }
            else{
            return (
              <option key = {idx} value={idx}>{radio.FunctionName}</option>

)
}
})}
</Form.Control>
</Form.Group>
</Form>
</div>
);
}

function ToastF(props){

const toastvar = props.txt=="FAILED!!! CHECK!!!" ? "danger" : "info";
const toast_output = JSON.stringify(props.txt,null,2);

return(
    <Toast className="d-inline-block m-1" bg={toastvar} onClose={() => props.changeToast(null)}>
      <Toast.Header>
        <strong className="me-auto">FPL Alerts Message</strong>
      </Toast.Header>
      <Toast.Body style={{textAlign:"left"}}>
      <pre><code>
        {toast_output}
        </code></pre>
      </Toast.Body>
    </Toast>
)
}
      // <pre><code>{JSON.stringify(JSON.parse(props.txt),null,2)}</code></pre>

function ScriptsTable(props) {

  if(props.adminData.length==0){
    return null
  }
  else{
    const scriptName = props.adminData.log.length ===0 ? null : props.adminData.log[0].job_name;

  return (
    <Table
      key="scripts_tab"
      hover
      size="sm"
      className={"shadow"}
      style={{backgroundColor:"white"}}
    >
      <thead className="table-dark">
        <tr>
          <th style={{textAlign:"left"}}>Name</th>
          <th>Last</th>
          <th>Today</th>
        </tr>
      </thead>
      <tbody>
        {props.adminData.scripts.map(plyr =>{
          const bdg = (x) => {if(x>0){return <Badge variant="danger">{x}</Badge>} else{return null}};
        return (
          <tr key={plyr.ord} style={{cursor:"pointer", padding:"0"}}
          onClick={()=>props.newScript(plyr.name,0)}
          className={
            plyr.name== scriptName
              ? "table-success"
              : null
          }>
            <td
              style={{textAlign:"left"}}
            >
              {plyr.name} {bdg(plyr.day_gap)}
            </td>
            <td>{plyr.last_run}</td>
            <td>
              {plyr.today_runs}
            </td>
          </tr>
        )})}
      </tbody>
    </Table>
  );
}
}

function ConfigTable(props) {

  if(props.adminData.length==0){
    return null
  }
  else{
const configName = props.configName;

  return (
    <Table
      key="config_tab"
      hover
      size="sm"
      className={"shadow"}
      style={{backgroundColor:"white"}}
    >
      <thead className="table-dark">
        <tr>
          <th style={{textAlign:"left"}}>Config</th>
          <th>Value</th>
          <th>Last</th>
        </tr>
      </thead>
      <tbody>
        {props.adminData.config.map(plyr => {
          const bdg = (x) => {if(x>0){return <Badge variant="danger">{x}</Badge>} else{return null}};
          return (
          <tr key={plyr.config_name} style={{cursor:"pointer", padding:"0"}}
          onClick={()=>props.setconfigName(plyr.config_name)}
          className={
            plyr.config_name== configName
              ? "table-success"
              : null
          }>
            <td
              style={{textAlign:"left"}}
            >
              {plyr.config_name} {bdg(plyr.day_gap)}
            </td>
            <td>{plyr.config_val}</td>
            <td>
              {plyr.upd_time}
            </td>
          </tr>
        )})}
      </tbody>
    </Table>
  );
}
}

// <Button target="#accordion" block>
// Show Logs</Button>
// <div id='accordion' className='collapse'>
// </div>

function LogsAccordion(props) {

  if(props.adminData.length==0){
    return null;
  }
  else{
    return (
      <>
<Accordion style ={{textAlign:"left",fontSize:"12px"}}>
<Card>
<Card.Header>
<Accordion.Toggle as={Button} variant="success" block eventKey="0">
  Logs
</Accordion.Toggle>
</Card.Header>
<Accordion.Collapse eventKey="0">
<Card.Body>

      <Accordion style ={{textAlign:"left",fontSize:"12px"}}>
      {props.adminData.log.map((plyr,idx) => (
      <Card key={idx}>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={idx+1} style ={{textAlign:"left",fontSize:"12px"}}>
            {plyr.job_name +" ("+plyr.upd_time+" )"}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={idx+1}>
        <pre><code>
          <Card.Body>{JSON.stringify(JSON.parse(plyr.log_text),null,2)}</Card.Body>
          </code></pre>
        </Accordion.Collapse>
      </Card>))}
      </Accordion>

      </Card.Body>
      </Accordion.Collapse>
      </Card>
    </Accordion>
    </>
);
}
}

// <pre><code>
//   <Card.Body>{JSON.stringify(JSON.parse(plyr.log_text),null,2)}</Card.Body>
//   </code></pre>

function ConfigInput (props) {
  if(props.adminData.length==0){
    return null;
  }
  else{
    return (
      <>
      <Row>
      <Col>
  <Form>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Control
    as="input"
    type="number"
    size="lg"
    defaultValue="0"
    onChange={(e) => props.setconfigVal(e.currentTarget.value)}
    />
  </Form.Group>
  </Form>
  </Col>
  <Col>
  <Button variant="danger" size="lg" block
  onClick={() => props.runConfig()}
  >
  Config
  </Button>
  </Col>
  </Row>
  </>
);
}
}

// {JSON.stringify(JSON.parse(plyr.log_text),null,'\t')}
//     <Accordion>
//   <Accordion.Item eventKey="0">
//     <Accordion.Header>Accordion Item #1</Accordion.Header>
//     <Accordion.Body>
//       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//       tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
//       veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
//       commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
//       velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
//       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
//       est laborum.
//     </Accordion.Body>
//   </Accordion.Item>
//   <Accordion.Item eventKey="1">
//     <Accordion.Header>Accordion Item #2</Accordion.Header>
//     <Accordion.Body>
//       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//       tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
//       veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
//       commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
//       velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
//       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
//       est laborum.
//     </Accordion.Body>
//   </Accordion.Item>
// </Accordion>
// </div>
//   )
// }
// }


// enable_rule
// {setChecked(e.currentTarget.checked)}

// <ToggleButton
//   key={idx}
//   id={`radio-${idx}`}
//   type="radio"
//   variant="outline-success"
//   name="radio"
//   checked={checked}
//   value="1"
//   onChange={(e) => setChecked(e.currentTarget.checked)}
// >
//   {radio.Name}
// </ToggleButton>


const loadSession = () => {

const [isAuthenticating, setIsAuthenticating] = useState(true);
const [isAuthenticated, userHasAuthenticated] = useState(false);
const [dataRules,updateDataRules] =useState([]);
const [listLamdas,updateLamdas] =useState([]);
const [lamdaSelect,changeLamda] =useState(0);
const [toastText,changeToast] =useState(null);
const [adminUrl, setadminUrl] = useState("https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/jr/admin/init")
const [adminData, setadminData] = useState([]);

const [configVal, setconfigVal] = useState(0);
const [configName, setconfigName] = useState();
const [scriptSelect, changeScript] = useState("update_config");
// const [configType, changeConfigType] = useState(0);

useEffect(() => {
  onLoad2();
}, []);

const onLoad2 = async () => {
// async function onLoad2() {
  try {
    await Auth.currentSession();
    await Auth.currentAuthenticatedUser({
          bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        const idT = user.signInUserSession.idToken.jwtToken;
        console.log(idT);
        AWS.config.region = 'eu-west-1'; // Region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
         IdentityPoolId: 'eu-west-1:af9ead72-49bd-4566-9abb-bbe3eaaf3909',
         Logins: {
           'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_9jhIk35RA': idT
         }
     });
     console.log(AWS.config.credentials);
     AWS.config.credentials.get(function(err) {
       if (err) {
         console.log("Error: "+err);
       }
       console.log("Cognito Identity Id: " + AWS.config.credentials.identityId);
       // loggedIn=true;
    });

    lambda = new AWS.Lambda({region: 'eu-west-1', apiVersion: '2015-03-31'});
    cloudwatchevents = new AWS.CloudWatchEvents({apiVersion: '2015-10-07'});

    var params = {};
    cloudwatchevents.listRules(params, function (err, data) {
      if (err) {console.log(err, err.stack);
        return null;} // an error occurred
      else     {
          // console.log(data);           // successful response
          // dataRules = data.Rules;
          updateDataRules(data.Rules);
          console.log("userHasAuthenticated_seesion_in_cw");
          // userHasAuthenticated(true);
      }
    });
    params = {MaxItems: 50};
    lambda.listFunctions(params, function (err, data) {
      if (err) {console.log(err, err.stack);
        return null;} // an error occurred
      else     {
          console.log(data.Functions);           // successful response
          // dataRules = data.Rules;
          updateLamdas(data.Functions);
          console.log("userHasAuthenticated_seesion_in_lm");
          userHasAuthenticated(true);
      }
    });
      })
      .catch(err => console.log(err));
  // userHasAuthenticated(true);
}
catch(e) {
  if (e !== 'No current user') {
    alert(e);
  }
}

setIsAuthenticating(false);
}


const refreshCW = async () => {
    cloudwatchevents.listRules({}, function (err, data) {
  if (err) {console.log(err, err.stack);
    return null;} // an error occurred
  else     {

      updateDataRules(data.Rules);
      console.log(data.Rules);
      console.log("userHasAuthenticated_refresh");
      userHasAuthenticated(true);
  }
});
}

const invokeLambda = () => {
changeToast(null)
// var fname = listLamdas[lamdaSelect].FunctionName;
lambda = new AWS.Lambda({region: 'eu-west-1', apiVersion: '2015-03-31'});
var params ={FunctionName:listLamdas[lamdaSelect].FunctionName};
lambda.invoke(params, function (err, data) {
if (err) {
console.log(err, err.stack);
alert("ERROR");
changeToast("FAILED!!! CHECK!!!");} // an error occurred
else     {
  console.log(data);
  // console.log("stat_code"+data.Payload);
  console.log("lambda_successful");
  // alert("lambda_successful!");
  // changeToast("lambda ran " + data.Payload);
  // console.log(data.Payload)
  if(data.Payload=="null"){
    changeToast("lambda_successful!");
  }
  else {
    changeToast(data.Payload);
}

  // alert("lambda_successful");
  // ToastF("lambda_successful");
}
});
}

const changeLamdaFunc = (fname) => {
changeLamda(fname);
console.log(listLamdas[fname].FunctionName);
}

const newScript = (script,configType) => {
    setadminUrl('https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/jr/admin/init?script_name='+script+'&config_type='+configType);
    changeScript(script);
  }


useEffect(() => {
fetchFpl(adminUrl);
console.log("useeffect ran");
const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
  fetchFpl(adminUrl);
  console.log("useeffect ran again");
}, 60000)

return () => {
  clearInterval(intervalId); //This is important
  console.log("clearInterval");
}

}, [adminUrl])

const fetchFpl = async (url) => {
console.log('fetch_url:'+url)
const response = await axios.get(url);
setadminData(response.data);
console.log("fetchran");
// setGW(response.data.status.gw);
};

const runConfig = async () => {

const url = "https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/jr/admin/"+scriptSelect+"?config_name="+configName+"&config_val="+configVal+"&gw=1";
try {
const response = await axios.get(url);
console.log(response.data);
fetchFpl(adminUrl);
const toast_output = JSON.stringify(response.data,null,2);
// console.log(toast_output);
// changeToast(response.data.input.config_name +" - "+response.data.input.config_val);
  // changeToast(toast_output);
  changeToast(response.data);
}
catch (error) {
  console.log(error);
  changeToast("FAILED!!! CHECK!!!");
}
};

return {isAuthenticating,
  isAuthenticated,
  dataRules,
  listLamdas,
  onLoad2,
  refreshCW,
  lamdaSelect,
  changeLamdaFunc,
  invokeLambda,
  toastText,
  changeToast,
  adminData,
  newScript,
  setconfigName,configName,
  setconfigVal,runConfig,
  fetchFpl,adminUrl
  };
}


function App() {

  const {
    isAuthenticating,
    isAuthenticated,
    dataRules,
    listLamdas,
    onLoad2,
    refreshCW,
    lamdaSelect,
    changeLamdaFunc,
    invokeLambda,
    toastText,
    changeToast,
    adminData,
    newScript,
    setconfigName,configName,
    setconfigVal,runConfig,
    fetchFpl,adminUrl,
  } = loadSession();

// {dataRules.length >0 ?dataRules[1].Name : "None"}
                    // <Button variant="dark" size="lg" onClick={() => onLoad2()} >{dataRules.length >0 ?dataRules[1].Name : "None"}</Button>

  return (
    <AmplifyAuthContainer>
    <AmplifyAuthenticator>
    <AmplifySignIn
  headerText="FPL ALerts Admin"
  slot="sign-in"
  hideSignUp = "true"
></AmplifySignIn>

    <div className="App">
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark"  sticky="top" style ={{margin:"0 0 20px 0"}}>
      <Navbar.Brand href="/">
        <img
          src={logo} alt="Logo"
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{' '}
        &nbsp; AdminX
      </Navbar.Brand>
      <Nav className="mr-auto"><div>
      <Navbar.Text style ={{color:"white"}}>
  {isAuthenticated ? "\u2705" : "\u274c"}
</Navbar.Text>
      <Button variant="info" onClick={() => {onLoad2()}}
      style={{marginLeft:"15px",marginRight:"15px"}} >AWS</Button>
      <Button variant="info" onClick={() => {fetchFpl(adminUrl)}} >Logs</Button>
            </div></Nav>
    </Navbar>
      <div style={{textAlign:"center",marginLeft:"0px",marginRight:"0px"}}>
      {toastText!=null ?(<ToastF txt={toastText} changeToast={changeToast}/>):null}
        <Container fluid style={{marginLeft:"0px",marginRight:"0px"}}>
          <Row style={{textAlign:"center",marginLeft:"0px",marginRight:"0px"}}>
          <Col xs={12} sm={4}>
            {isAuthenticated ? (
                    dataRules.length >0 ?
                    <>

                    <Row>
                    <Col>
                    <LambdaRules2 lambdas={listLamdas} lamdaSelect={lamdaSelect} changeLamdaFunc={changeLamdaFunc}/>
                    </Col>
                    <Col>
                    <Button variant="danger" size="lg" onClick={() => invokeLambda()}
                    style={{width:"100%"}}
                    >Run</Button>
                    <br/>
                    </Col>
                    </Row>
                    <CwRules2 cw={dataRules} onLoad2={onLoad2} refreshCW={refreshCW} changeToast={changeToast}/>
                    </>
                     : null
                  ) : (
                    <Button variant="dark" size="lg" onClick={() => onLoad2()} >NOT AUTHED</Button>
                      )}
          </Col>
          <Col xs={12} sm={4}
          style = {{	fontSize:"12px"}}>
          <ConfigInput
          adminData={adminData} setconfigVal={setconfigVal} runConfig={runConfig}
          />
          <ScriptsTable
            adminData={adminData} newScript={newScript}
          />
          <LogsAccordion
          adminData={adminData}
          style ={{textAlign:"left"}}
        />
        <br/>
          </Col>
          <Col xs={12} sm={4}
          style = {{	fontSize:"12px"}}>
          <ConfigTable
            adminData={adminData} setconfigName={setconfigName} configName={configName}
          />

          <Button variant="danger" size="lg" onClick={() => newScript(null,1)}
          style={{width:"100%"}}
          >More</Button>
          <Row>
          <br/>
          </Row>
          </Col>

          </Row>

        </Container>
        <br/>
      </div>
      <AmplifySignOut />
    </div>
    </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  );
  }

  // export default withAuthenticator(App);
  // export default withAuthenticator(App);
  export default App;
